import React from 'react';
import { graphql } from 'gatsby';
/*import styled from "@emotion/styled";*/
/*import colors from "styles/colors";*/
import Layout from "components/Layout";
import SEO from "components/SEO";
import Hero from 'components/Hero';
import PostContent from 'components/_page/newsroom/PostContent';

const RenderBody = ({ page }) => (
  <>
    <Hero
      title={page.title && page.title[0].text}
      variant='condensed'
    />

    {(page.body && page.body.length > 0) && (
      <PostContent body={page.body}/>
    )}
  </>
);

export default ({ data }) => {
  const page = data.prismic.pageByUID;

  if (!page) return null;

  return (
    <Layout>
      <SEO
        title={page.social_title ? page.social_title : page.title[0].text || 'Simple Page'}
        description={page.social_description ? page.social_description : null}
        image={page.social_image ? page.social_image.url : null}
      />

      <RenderBody
        page={page}
      />
    </Layout>
  )
}

export const query = graphql`
  query PageQuery($uid: String!) {
    prismic {
      pageByUID: simple_page(uid: $uid, lang: "en-us") {
        title
        content
        body {
          ... on PRISMIC_Simple_pageBodyText {
            type
            primary {
              content
            }
          }
          ... on PRISMIC_Simple_pageBodyCustom_html {
            type
            primary {
              content
            }
          }
        }
        social_title
        social_description
        social_image
      }
    }
  }
`;
